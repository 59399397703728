.tabs-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.tabs {
  display: flex;
  justify-content: space-around;
  border-bottom: 2px solid #ccc;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.tab.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.tabpanel {
  display: none;
  padding: 20px;
  animation: fadeIn 0.5s ease-in-out;
}

.tabpanel.active {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
